import React from "react"
import Helmet from "react-helmet"
import { Link, graphql, withPrefix } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const latestPosts = this.props.data.allMdx
    const { previous, next } = this.props.pageContext
    const tags = post.frontmatter.tags

    return (
      <Layout isShowNav={false}>
        <Helmet>
          <script src={withPrefix("jquery.js")} type="text/javascript" />
          <script src={withPrefix("jquery.sticky.js")} type="text/javascript" />
          <script
            src={withPrefix("jquery.stellar.min.js")}
            type="text/javascript"
          />
          <script src={withPrefix("wow.min.js")} type="text/javascript" />
          <script src={withPrefix("custom.js")} type="text/javascript" />
        </Helmet>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <section id="news-detail" data-stellar-background-ratio="0.5">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-sm-7">
                <div class="news-detail-thumb">
                  <h2>{post.frontmatter.title}</h2>
                  <MDXRenderer>{post.body}</MDXRenderer>
                </div>
              </div>

              <div class="col-md-4 col-sm-5">
                <div class="news-sidebar">
                  <div class="news-author">
                    <h4>Dodano</h4>
                    <p>{post.frontmatter.date}</p>
                  </div>

                  <div class="recent-post">
                    <h4>Ostatnio dodane</h4>
                    {latestPosts.edges.map(({ node }) => {
                      return <PostLink node={node} />
                    })}
                  </div>

                  {previous && (
                    <div class="recent-post">
                      <h4>Poprzedni</h4>
                      <PostLink node={previous} />
                    </div>
                  )}

                  {next && (
                    <div class="recent-post">
                      <h4>Następny</h4>
                      <PostLink node={next} />
                    </div>
                  )}

                  <div class="news-tags">
                    <h4>Tagi</h4>
                    {tags.map((tag, i) => {
                      return (
                        <li>
                          <a href="#">{tag}</a>
                        </li>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />
          </div>
        </section>
      </Layout>
    )
    function PostLink({ node }) {
      const title = node.frontmatter.title || node.fields.slug
      return (
        <div class="media" style={{ alignItems: "center", marginTop: "15px" }}>
          <div class="media-object pull-left">
            <Link to={`/blog${node.fields.slug}`}>
              <img
                src={node.frontmatter.thumbnail?.publicURL}
                class="img-responsive"
                alt=""
                style={{
                  objectFit: `cover`,
                  marginBottom: "auto",
                }}
              />
            </Link>
          </div>
          <div class="media-body">
            <h4 class="media-heading" style={{ margin: "auto" }}>
              <Link to={`/blog${node.fields.slug}`}>{title}</Link>
            </h4>
          </div>
        </div>
      )
    }
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "pl")
        thumbnail { publicURL }
        tags
        description
      }
    }
    allMdx(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "pl")
            title
            thumbnail { publicURL }
            description
          }
          body
        }
      }
    }
  }
`
